import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const DoughnutChart = ({ label, percentage, completedLabel, notCompletedLabel }: any) => {
  const notCompletedPercentage = parseFloat((100 - percentage).toFixed(2)); // Fix floating-point precision

  const data = {
    labels: [`${completedLabel}: ${percentage}%`, `${notCompletedLabel}: ${notCompletedPercentage}%`],
    datasets: [
      {
        data: [percentage, notCompletedPercentage],
        backgroundColor: ['#4CAF50', '#F44336'],
        hoverBackgroundColor: ['#66BB6A', '#EF5350'],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div style={{ width: '100%', maxWidth: '400px', height: '100%', position: 'relative', display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <Doughnut data={data} options={options} />
        <div style={{ position: 'absolute', top: '10px', left: '10px', fontSize: '19px', fontWeight: 'bold', color: 'black' }}>
          {label}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '20px' }}>
        <p style={{ fontSize: '14px', margin: '5px 0', display: 'flex', alignItems: 'center', color: 'black' }}>
          <span style={{ width: '10px', height: '10px', backgroundColor: '#4CAF50', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }}></span>
          <span>{completedLabel}: {percentage}%</span>
        </p>
        <p style={{ fontSize: '14px', margin: '5px 0', display: 'flex', alignItems: 'center', color: 'black' }}>
          <span style={{ width: '10px', height: '10px', backgroundColor: '#F44336', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }}></span>
          <span>{notCompletedLabel}: {notCompletedPercentage}%</span>
        </p>
      </div>
    </div>
  );
};

export default DoughnutChart;
