import React, { useEffect, useState } from "react";
import {
  MdAccountCircle,
  MdEdit,
  MdLocationOn,
  MdOutlineCalendarMonth,
} from "react-icons/md";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TitleBar from "./TitleBar";
import { BiSolidCarWash } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/reducers";
import { fetchInvoices } from "../store/actions/invoiceActions"; // Import fetchInvoices
import { Invoice, Customer } from "../store/actions/types"; // Import the Invoice and Customer types
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import NoRecords from "../NoRecords";
import FilterComponent from "./FilterComponent";
import { Properties } from "csstype";
import LoadingSpinner from "../LoadingSpinner";

const defaultCustomer: Customer = {
  uid: "",
  firstName: "Unknown",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  profileImage: "",
  dateOfBirth: "",
  createdAt: "",
  gender: "",
  stripeCustomerId: "",
  verified: false,
  addresses: [],
  vehicles: [],
};

const Tabs = ({
  activeTab,
  onTabClick,
}: {
  activeTab: string;
  onTabClick: (tab: string) => void;
}) => {
  return (
    <div style={styles.tabs}>
      {["New", "Action Required", "Sent", "Done"].map((tab) => (
        <div
          key={tab}
          style={activeTab === tab ? styles.activeTab : styles.tab}
          onClick={() => onTabClick(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

const Invoices = () => {
  const [activeTab, setActiveTab] = useState<string>("New");
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  const invoices = useSelector((state: RootState) => state.invoices.invoices);

  const loadingInvoices = useSelector(
    (state: RootState) => state.invoices.loading
  );

  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const auth = getAuth();

  useEffect(() => {
    if (selectedInvoiceId) {
      const updatedInvoice = invoices.find(
        (invoice) => invoice.id === selectedInvoiceId
      );
      if (updatedInvoice) {
        setSelectedInvoice(updatedInvoice);
      }
    }
  }, [invoices, selectedInvoiceId]);

  useEffect(() => {
    const fetchAndListenInvoices = async (uid: string) => {
      dispatch(fetchInvoices(uid));
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchAndListenInvoices(user.uid);
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const handleInvoiceClick = (invoice: Invoice) => {
    console.log(invoice)
    if (!invoice.pdf) {
      alert("No invoice available for download.");
      return;
    }
  
    // Convert Base64 string to a Blob
    const byteCharacters = atob(invoice.pdf);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
  
    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);
  
    // Create a temporary anchor element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `invoice-${invoice.orderId}.pdf`; // Use orderId or any unique identifier for filename
    document.body.appendChild(a);
    a.click();
  
    // Clean up by removing the temporary anchor element and revoking the Blob URL
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
  

  const handleBackClick = () => {
    setSelectedInvoice(null);
  };

  const handleFilterChange = (filters: {
    account: string;
    dateFrom: string;
    dateTo: string;
  }) => {
    // Implement filter logic here
    console.log(filters);
  };

  const filteredInvoices = invoices.filter(
    (invoice) =>
      (activeTab === "New" &&
        ["New", "Pending"].includes(invoice.invoiceStatus)) ||
      invoice.invoiceStatus === activeTab
  );

  const formatDate = (timestamp: number | null) => {
    if (!timestamp) return "TBA";
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const DownloadInvoiceButton = ({
    pdfBase64,
  }: {
    pdfBase64: string | null;
  }) => {
    const downloadPdf = () => {
      if (!pdfBase64) {
        alert("No invoice available for download.");
        return;
      }

      // Convert Base64 string to a Blob
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "invoice.pdf"; // Generate a filename if needed
      document.body.appendChild(a);
      a.click();

      // Clean up by removing the temporary anchor element and revoking the Blob URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };

    return (
      <button
        style={{
          padding: "5px 15px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={downloadPdf}
      >
        Download PDF
      </button>
    );
  };

  const InvoiceDetail = ({
    invoice,
    onClick,
  }: {
    invoice: Invoice;
    onClick: () => void;
  }) => {
    return (
      <div style={styles.invoiceDetailRow} onClick={() => handleInvoiceClick(invoice)}>
        <div style={styles.column}>{invoice.orderId}</div>
        <div style={styles.column}>{formatDate(invoice.timestamp)}</div>
        <div style={styles.column}>{formatDate(invoice.timestampSent)}</div>
        <div style={styles.column}>{invoice.orderService}</div>
        <div style={styles.column}>{invoice.orderStatus}</div>
        <div style={styles.column}>{invoice.alerts || "No alerts"}</div>
        <div style={styles.column}>${invoice.finalInvoiceAmount}</div>
        <div style={styles.column}>
          {invoice.invoiceStatus === "New" ? (
            <button style={styles.sendButton}>Send</button>
          ) : (
            invoice.invoiceStatus
          )}
        </div>

        <div style={styles.editIcon}>
          <MdEdit />
        </div>
      </div>
    );
  };

  const InvoiceHeader = () => {
    return (
      <div style={styles.invoiceHeaderRow}>
        <div style={styles.columnHeader}>Order ID</div>
        <div style={styles.columnHeader}>Created Date</div>
        <div style={styles.columnHeader}>Sent Date</div>
        <div style={styles.columnHeader}>Service Type</div>
        <div style={styles.columnHeader}>Order Status</div>
        <div style={styles.columnHeader}>Alerts</div>
        <div style={styles.columnHeader}>Total</div>
        <div style={styles.columnHeader}>Status</div>
        <div style={styles.columnHeader}></div> {/* For the edit icon column */}
      </div>
    );
  };

  return (
    <div style={stylesApp.app}>
      <TitleBar title="Invoices" />
      {selectedInvoice ? (
        <InvoiceDetail invoice={selectedInvoice} onClick={handleBackClick} />
      ) : (
        <>
          {loadingInvoices ? (
            <div style={{ marginTop: "-30%" }}>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Tabs activeTab={activeTab} onTabClick={handleTabClick} />
              <FilterComponent onFilterChange={handleFilterChange} />
              <InvoiceHeader />
              {filteredInvoices.length > 0 ? (
                filteredInvoices.map((invoice, index) => (
                  <InvoiceDetail
                    key={index}
                    invoice={invoice}
                    onClick={() => handleInvoiceClick(invoice)}
                  />
                ))
              ) : (
                <NoRecords />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const styles: { [key: string]: Properties<string | number, string> } = {
  tabs: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    fontSize: "18px",
    justifyContent: "center",
    padding: "10px",
    marginLeft: "17px",
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    marginBottom: "30px",
    maxWidth: 470,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  tab: {
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "10px",
    margin: "0 5px",
  },
  activeTab: {
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "10px",
    margin: "0 5px",
    backgroundColor: "#007bff",
    color: "white",
  },
  invoiceHeaderRow: {
    display: "grid",
    gridTemplateColumns: "16% 11% 11% 11% 11% 11% 12% 12% 5%",
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #ddd",
    borderTop: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
  invoiceDetailRow: {
    display: "grid",
    gridTemplateColumns: "16% 11% 11% 11% 11% 11% 12% 12% 5%",
    borderBottom: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    alignItems: "center",
    cursor: "pointer",
  },
  column: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "10px",
  },
  columnHeader: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "10px",
    borderBottom: "1px solid #ddd",
    borderTop: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
  editIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sendButton: {
    padding: "3px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "600",
  },
};

const stylesApp: { [key: string]: Properties<string | number, string> } = {
  app: {
    textAlign: "center",
    padding: "20px",
  },
};

export default Invoices;
