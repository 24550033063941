//@ts-nocheck
import React, { useEffect, useState } from "react";
import {
  MdOutlineCalendarMonth,
  MdAccessTime,
  MdAccountCircle,
} from "react-icons/md";
import { BiSolidCarWash } from "react-icons/bi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Order, Customer } from "./store/actions/types"; // Import the Order and Customer types
import { formatBase64Image } from "../Functions";
import { CSSProperties } from "react";
import MapComponent from "./MapComponent";
import { FaCar } from "react-icons/fa"; // Import the car icon
import ReactDOM from "react-dom";
import { activeColor } from "../../styles";
import { db } from "../../App";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { RootState } from "./store/reducers";

interface Provider {
  uid: string;
  emailAddress: string;
}

const BookingDetail = ({
  order,
  customer,
  onBack,
  admin = false,
}: {
  order: Order;
  customer: Customer;
  onBack: () => void;
  admin?: boolean;
}) => {
  //const iconColor = admin ? "black" : "#007ae4"; // Add this line
  const iconColor = admin ? "#007ae4" : "#007ae4"; // Add this line

  const [providers, setProviders] = useState<Provider[]>([]);
  const [selectedProvider, setSelectedProvider] = useState("");

  const { zones, regions } = useSelector((state: RootState) => state.zoneData);

  console.log(order.inspectImageUrls);

  const getRegionNameById = (id: string) => {
    const region = regions.find((region) => region.id === id);
    return region ? region.RegionName : "Region not found";
  };

  const getZoneNameById = (id: string) => {
    const zone = zones.find((zone) => zone.id === id);
    return zone ? zone.ZoneName : "Zone not found";
  };

  const downloadInvoice = () => {
    const pdfBase64 = order.payment; // Ensure 'payment' or similar property holds your Base64 data
    if (!pdfBase64) {
      alert("No invoice available for download.");
      return;
    }

    const byteCharacters = atob(pdfBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "invoice.pdf"; // Modify as needed
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const assignProvider = async () => {
    if (false) {
      try {
        const orderDocRef = doc(db, "Orders", order.id);
        await updateDoc(orderDocRef, {
          provider: selectedProvider,
          elevate: false,
        });
        console.log(
          `Provider ${selectedProvider} assigned to order ${order.id}.`
        );
        setSelectedProvider("");
      } catch (error) {
        console.error("Error assigning provider: ", error);
      }
    }
  };

  const loadProviders = async () => {
    if (admin && order.elevate) {
      try {
        const zonesQuery = query(
          collection(db, "Zones"),
          where("regionId", "==", order.regionId)
        );
        const zonesSnapshot = await getDocs(zonesQuery);
        const providerIds = new Set<string>();

        zonesSnapshot.forEach((zoneDoc) => {
          const zoneData = zoneDoc.data();
          const assignedProviders = zoneData.AssignedProviders || [];
          assignedProviders.forEach((provider: any) => {
            providerIds.add(provider.UID);
          });
        });

        const providersArray: Provider[] = [];
        for (const uid of providerIds) {
          const providerDocRef = doc(db, "Providers", uid);
          const providerDoc = await getDoc(providerDocRef);
          if (providerDoc.exists()) {
            const data = providerDoc.data();
            providersArray.push({ uid, emailAddress: data.emailAddress });
          }
        }

        setProviders(providersArray);
        console.log("Providers: ", providersArray);
      } catch (error) {
        console.error("Error loading providers: ", error);
      }
    }
  };

  useEffect(() => {
    loadProviders();
  }, [admin, order.elevate, order.regionId]);

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const formatTime = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  const formatPercentage = (value: number) => {
    return `${(value * 100).toFixed(0)}%`;
  };

  const getHistoryBackground = (historyStatus: string, orderStatus: string) => {
    if (historyStatus === orderStatus) {
      switch (historyStatus) {
        case "Pending":
        case "Assigned":
          return "flash-yellow-animation"; // Apply flashing yellow for Pending or Assigned
        case "Ongoing":
          return "flash-navy-animation"; // Apply flashing navy for Ongoing
        default:
          return ""; // No special animation class for other statuses
      }
    }
    return ""; // Return empty class if statuses don't match
  };

  const getStatusColor = (status: string) => {
    let statusColor = "#007bff"; // Default color

    if (status === "Pending") {
      statusColor = "#FFCC00"; // Darker yellow
    } else if (status === "Assigned") {
      statusColor = "#FFCC00"; // Darker yellow
    } else if (status === "Scheduled") {
      statusColor = "#9C009C";
    } else if (status === "Ongoing") {
      statusColor = activeColor;
    } else if (status === "Complete") {
      statusColor = "#007bff"; // Default color
    } else if (status === "Canceled") {
      statusColor = "red";
    }

    return statusColor;
  };

  const getStatusText = (
    orderStatus: string,
    elevate: boolean,
    admin: boolean
  ) => {
    if (elevate && admin) {
      return "Elevate";
    }
    return orderStatus;
  };

  const center = {
    lat: order.address.location.latitude,
    lng: order.address.location.longitude,
  };
  const zoom = 11;

  const onMapLoad = (map: google.maps.Map, maps: typeof google.maps) => {
    const infowindow = new maps.InfoWindow({
      content: `<div><strong>Order Location</strong><br />${order.address.streetAddress}, ${order.address.cityAddress}, ${order.address.stateAddress} ${order.address.zipCodeAddress}</div>`,
    });

    // Create a custom marker element
    const markerDiv = document.createElement("div");
    ReactDOM.render(<FaCar color="#007ae4" size={24} />, markerDiv);

    // Convert the React component to a data URL
    const markerIcon = {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
        markerDiv.innerHTML
      )}`,
      scaledSize: new maps.Size(34, 34),
    };

    // Add the marker to the map
    const marker = new maps.Marker({
      position: center,
      map,
      title: "Order Location",
      icon: markerIcon,
    });

    marker.addListener("click", () => {
      infowindow.open(map, marker);
    });

    infowindow.open(map, marker);
  };

  const dynamicStyles: { [key: string]: CSSProperties } = {
    dropdown: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      border: "1px solid #ddd",
      width: "100%",
      //marginBottom: "10px",
    },
    assignButton: {
      padding: "10px 20px",
      fontFamily: "Roboto-Bold",
      fontSize: "16px",
      backgroundColor: iconColor,
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    assignedIconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: iconColor,
      marginRight: "10px",
    },
    addressIconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: iconColor,
      marginRight: "10px",
    },
    serviceIconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: iconColor,
      marginRight: "10px",
    },
    backButton: {
      padding: "25px 40px",
      fontFamily: "Roboto-Bold",
      fontSize: "20px",
      backgroundColor: iconColor,
      color: "white",
      border: "none",
      borderRadius: "50px",
      cursor: "pointer",
    },
    downloadButton: {
      padding: "25px 50px",
      fontFamily: "Roboto-Bold",
      fontSize: "20px",
      backgroundColor: iconColor,
      color: "white",
      border: "none",
      borderRadius: "50px",
      cursor: "pointer",
    },
  };

  return (
    <div style={detailStyles.scrollContainer}>
      <div style={detailStyles.detailContainer}>
        <div style={detailStyles.orderCardHeader}>
          {customer.profileImage ? (
            <img
              src={formatBase64Image(customer.profileImage)}
              alt="Customer Profile Icon"
              style={detailStyles.profileImage}
            />
          ) : (
            <MdAccountCircle size={50} style={detailStyles.avatarIcon} />
          )}

        
          <div style={detailStyles.orderDetails}>
            <>
              <div style={detailStyles.infoItem}>
                <h3 style={detailStyles.userName}>
                  {customer.firstName} {customer.lastName}
                </h3>
              </div>
              <div style={detailStyles.infoItem}>
                <span style={{ fontFamily: "Roboto", fontSize: 18 }}>
                  Service Charge:
                </span>
                <span
                  style={{
                    ...detailStyles.serviceCharge,
                    paddingRight: "15px",
                  }}
                >
                  ${order.orderPrice}
                </span>
                <MdOutlineCalendarMonth
                  size={20}
                  style={detailStyles.iconTop}
                />
                <span
                  style={{ ...detailStyles.ellipsis, paddingRight: "15px" }}
                >
                  {formatDate(order.dateOrderPlaced)}
                </span>
                <MdAccessTime size={20} style={detailStyles.iconTop} />
                <span style={detailStyles.ellipsis}>
                  {formatTime(order.dateOrderPlaced)}
                </span>
              </div>
              <hr style={detailStyles.separator} />
              <div style={detailStyles.rowContainer}>
                <div style={detailStyles.vehicleDetails}>
                  <div style={detailStyles.vehicleInfo}>
                    <div>
                      <div style={detailStyles.vehicleLabel}>Vehicle</div>

                      <img
                        src={order.vehicle.imageSource}
                        alt="Vehicle"
                        style={detailStyles.vehicleImage}
                      />
                    </div>
                    <div>
                      <div style={detailStyles.vehicleLabel}>Make</div>

                      <div style={detailStyles.vehicleItem}>
                        <div style={detailStyles.vehicleValue}>
                          {order.vehicle.make}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={detailStyles.vehicleLabel}>Model</div>

                      <div style={detailStyles.vehicleItem}>
                        <div style={detailStyles.vehicleValue}>
                          {order.vehicle.model}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={detailStyles.vehicleLabel}>Color</div>
                      <div style={detailStyles.vehicleItem}>
                        <div style={detailStyles.vehicleValue}>
                          {order.vehicle.color}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={detailStyles.rowContainer}>
                <div style={{ ...detailStyles.rowItem, flex: 1 }}>
                  <div style={detailStyles.label}>Service</div>
                  <div style={detailStyles.serviceItem}>
                    <div style={dynamicStyles.serviceIconContainer}>
                      <BiSolidCarWash
                        size={20}
                        style={detailStyles.serviceIcon}
                      />
                    </div>
                    <span style={detailStyles.serviceText}>
                      {order.service}
                    </span>
                  </div>
                </div>
                <div style={{ ...detailStyles.rowItem, flex: 1 }}>
                  <div style={detailStyles.label}>Assigned to</div>
                  <div style={detailStyles.assignedItem}>
                    <div style={dynamicStyles.assignedIconContainer}>
                      <MdAccountCircle
                        size={20}
                        style={detailStyles.assignedIcon}
                      />
                    </div>
                    <span style={detailStyles.assignedText}>Jean Silver</span>
                  </div>
                </div>
              </div>
                
              <div style={detailStyles.label}>Address</div>
              <div style={detailStyles.addressItem}>
                <div style={dynamicStyles.addressIconContainer}>
                  <HiOutlineLocationMarker
                    size={20}
                    style={detailStyles.addressIcon}
                  />
                </div>
                <span style={detailStyles.addressText}>
                  {`${order.address.streetAddress}, ${order.address.cityAddress}, ${order.address.stateAddress} ${order.address.zipCodeAddress}`}
                </span>
              </div>
                            {/*    */}

              {order.onRouteToOngoing !== undefined && (
                <>
                  <div style={detailStyles.label}>Statistics</div>
                  <div style={{ ...detailStyles.rowContainer, gap: "40px" }}>
                    <div style={detailStyles.rowItem}>
                      <div style={detailStyles.label}>ETA</div>
                      <div style={detailStyles.statisticsItem}>
                        <span style={detailStyles.statisticsText}>
                          {order.eta} Min
                        </span>
                      </div>
                    </div>
                    <div style={detailStyles.rowItem}>
                      <div style={detailStyles.label}>ATA</div>
                      <div style={detailStyles.statisticsItem}>
                        <span style={detailStyles.statisticsText}>
                          {parseFloat(
                            order.onRouteToOngoing.toString()
                          ).toFixed(2)}{" "}
                          Min
                        </span>
                      </div>
                    </div>

                    <div style={detailStyles.rowItem}>
                      <div style={detailStyles.label}>
                        Distance Travelled to Order
                      </div>
                      <div style={detailStyles.statisticsItem}>
                        <span style={detailStyles.statisticsText}>
                          {parseFloat(order.distanceOnRoute.toString()).toFixed(
                            2
                          )}{" "}
                          KM
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
                 
              {order.regionId && (
                <>
                  <div style={detailStyles.label}>Regional Assignment</div>
                  <div style={detailStyles.rowContainer}>
                    <div style={detailStyles.rowItem}>
                      <div style={detailStyles.label}>Region</div>
                      <div style={detailStyles.statisticsItem}>
                        <span style={detailStyles.statisticsText}>
                          {getRegionNameById(order.regionId)}
                        </span>
                      </div>
                    </div>

                    {order.currentZoneId && (
                      <div style={detailStyles.rowItem}>
                        <div style={detailStyles.label}>Zone</div>
                        <div style={detailStyles.statisticsItem}>
                          <span style={detailStyles.statisticsText}>
                            {getZoneNameById(order.currentZoneId)}
                          </span>
                        </div>
                      </div>
                    )}

                    {order.providerRate != null && (
                      <div style={detailStyles.rowItem}>
                        <div style={detailStyles.label}>Rate</div>
                        <div style={detailStyles.statisticsItem}>
                          <span style={detailStyles.statisticsText}>
                            {formatPercentage(order.providerRate)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
           

              {order.inspectImageUrls && (
                <div style={{ ...detailStyles.rowContainer, marginTop: 20 }}>
                  <div>
                    <div
                      style={{
                        ...detailStyles.vehicleLabel,
                        marginLeft: "0px",
                      }}
                    >
                      Damage Images
                    </div>
                    <div style={detailStyles.imageContainer}>
                      {order.inspectImageUrls.damage.map((url, index) => (
                        <img
                          key={`damage-${index}`}
                          src={url}
                          alt="Damage"
                          style={detailStyles.vehicleImage}
                        />
                      ))}
                    </div>
                  </div>

                  {order.inspectImageUrls.before &&
                    order.inspectImageUrls.before.length > 0 && (
                      <div>
                        <div
                          style={{
                            ...detailStyles.vehicleLabel,
                            marginLeft: "0px",
                          }}
                        >
                          Before Images
                        </div>
                        <div style={detailStyles.imageContainer}>
                          {order.inspectImageUrls.before.map((url, index) => (
                            <img
                              key={`before-${index}`}
                              src={url}
                              alt="Before"
                              style={detailStyles.vehicleImage}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                  {order.inspectImageUrls.after &&
                    order.inspectImageUrls.after.length > 0 && (
                      <div>
                        <div
                          style={{
                            ...detailStyles.vehicleLabel,
                            marginLeft: "0px",
                          }}
                        >
                          After Images
                        </div>
                        <div style={detailStyles.imageContainer}>
                          {order.inspectImageUrls.after.map((url, index) => (
                            <img
                              key={`after-${index}`}
                              src={url}
                              alt="After"
                              style={detailStyles.vehicleImage}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              )}

              {(order.orderStatus === "Complete" ||
                order.orderStatus === "Canceled") &&
                order.rating && (
                  <div style={detailStyles.ratingContainer}>
                    <h3 style={detailStyles.ratingHeader}>Rating:</h3>
                    <div style={detailStyles.starsContainer}>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <span
                          key={star}
                          style={{
                            ...detailStyles.star,
                            color: order.rating >= star ? "gold" : "gray",
                          }}
                        >
                          ★
                        </span>
                      ))}
                    </div>
                  </div>
                )}

              {admin && order.elevate && (
                <>
                  <div style={detailStyles.vehicleLabel}>
                    Update Assigned Provider
                  </div>
                  <div style={detailStyles.rowContainer}>
                    <div style={detailStyles.rowItem}>
                      <select
                        style={dynamicStyles.dropdown}
                        onChange={(e) => setSelectedProvider(e.target.value)}
                        value={selectedProvider}
                      >
                        <option value="">Select Provider</option>
                        {providers.map((provider) => (
                          <option key={provider.uid} value={provider.uid}>
                            {provider.emailAddress}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={detailStyles.rowItem}>
                      <button
                        style={dynamicStyles.assignButton}
                        onClick={assignProvider}
                      >
                        Assign
                      </button>
                    </div>
                  </div>
                </>
              )}

              {admin && (
                <>
                  <div style={detailStyles.vehicleLabel}>
                    Update Order Status
                  </div>

                  <div style={detailStyles.rowContainer}>
                    <div style={detailStyles.rowItem}>
                      <select
                        style={dynamicStyles.dropdown}
                        onChange={(e) => setSelectedProvider(e.target.value)}
                        value={selectedProvider}
                      >
                        <option value="">Select Status</option>
                        <option value="Pending">Pending</option>
                        <option value="On Route">On Route</option>
                        <option value="Ongoing">Ongoing</option>
                        <option value="Scheduled">Scheduled</option>
                        <option value="Canceled">Canceled</option>
                        <option value="Complete">Complete</option>
                      </select>
                    </div>
                    <div style={detailStyles.rowItem}>
                      <button
                        style={dynamicStyles.assignButton}
                        onClick={() => console.log("Update Order clicked")} // Placeholder action
                      >
                        Update Order
                      </button>
                    </div>
                  </div>
                </>
              )}

              <div style={detailStyles.label}>Map Location</div>
            </>
            <div style={detailStyles.mapContainer}>
              <MapComponent
                center={center}
                zoom={zoom}
                onMapLoad={onMapLoad}
                //height={"300px"}
              />
            </div>
          </div>
          <div
            style={{
              ...detailStyles.orderStatus,
              backgroundColor: getStatusColor(order.orderStatus),
            }}
            className={getHistoryBackground(
              order.orderStatus,
              order.orderStatus
            )} // Apply the class conditionally
          >
            {getStatusText(order.orderStatus, order.elevate, admin)}
          </div>



        </div>
        
      </div>
      
      <div style={detailStyles.buttonBar}>
        <button onClick={onBack} style={dynamicStyles.backButton}>
          Back
        </button>
        {order.orderStatus === "Complete" && (
          <button
            style={dynamicStyles.downloadButton}
            onClick={downloadInvoice}
          >
            Download Invoice
          </button>
        )}
      </div>
    </div>
  );
};

const detailStyles: { [key: string]: CSSProperties } = {
  ratingContainer: {
    display: "flex",
    flexDirection: "row", // Align stars and text in a row
    alignItems: "center", // Vertically align to the center
    margin: "20px 0",
    justifyContent: "flex-start", // Align with the rest of the content
  },
  ratingHeader: {
    fontSize: "20px",
    fontWeight: "600",
    marginRight: "10px", // Add some space between the text and stars
  },
  starsContainer: {
    display: "flex",
    justifyContent: "flex-start", // Align stars to the start
  },
  star: {
    fontSize: "30px",
    margin: "0 5px",
  },

  statisticsItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px", // Adjust padding: top, right, bottom, left
    border: "1px solid #ddd",
    borderRadius: "100px",
    backgroundColor: "#fff",
    marginBottom: "10px",
    textAlign: "left",
    maxWidth: "fit-content", // Adjust width based on content
    minWidth: 20,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  statisticsText: {
    color: "#000",
    fontSize: "18px",
    fontFamily: "Roboto-Medium",
    textAlign: "left",
    whiteSpace: "nowrap", // Ensure the text stays on one line
    overflow: "hidden", // Hide overflow text
    textOverflow: "ellipsis", // Show ellipsis (...) for overflow text
  },

  scrollContainer: {
    height: "84vh", // Full viewport height
    overflowY: "auto", // Enable vertical scrolling
    margin: "20px", // Add padding to avoid overlap with the fixed button bar
    borderBottomLeftRadius: "15px", // Add border radius to bottom-left corner
    borderBottomRightRadius: "15px", // Add border radius to bottom-right corner
  },
  mapContainer: {
    backgroundColor: "white",
    width: "80%", // Use the full width of the parent container
    height: "500px", // Adjust the height as needed for your design
    padding: 20,
    marginBottom: "20px",
    overflow: "hidden", // Ensure the map does not overflow its container
    borderRadius: 10,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  vehicleContainer: {
    marginBottom: "20px",
  },
  vehicleDetails: {
    display: "flex",
    alignItems: "center",
  },
  vehicleImage: {
    width: "100px",
    height: "60px",
    borderRadius: "10px",
    marginRight: "20px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
  },
  vehicleInfo: {
    display: "flex",
    flexDirection: "row",
  },
  vehicleItem: {
    display: "flex",
    marginRight: 20,
    alignItems: "center",
    padding: "20px", // Adjust padding: top, right, bottom, left
    border: "1px solid #ddd",
    borderRadius: "100px",
    backgroundColor: "#fff",
    marginBottom: "10px",
    textAlign: "left",
    maxWidth: "fit-content", // Adjust width based on content
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  vehicleLabel: {
    fontSize: "16px",
    fontFamily: "Roboto-Medium",
    color: "#000",
    textAlign: "left",
    marginLeft: "10px",
    marginBottom: 10,
  },
  vehicleValue: {
    fontSize: "18px",
    fontFamily: "Roboto-Medium",
    color: "#000",
  },
  rowContainer: {
    display: "flex",
    alignItems: "center", // Center align items vertically
    gap: "20px", // Add gap between the items
    marginBottom: "10px",
    flexWrap: "nowrap", // Ensure items do not wrap to the next line
    //width:'100%',
    maxWidth: 700,
    overflowX: "auto", // Enable horizontal scrolling
  },

  rowItem: {
    display: "flex",
    flexDirection: "column", // Keep column to stack label and content vertically
    alignItems: "flex-start",

    //flex: "1", // Ensure items take equal space
  },

  serviceItem: {
    display: "flex",
    alignItems: "center",
    padding: "20px", // Adjust padding: top, right, bottom, left
    border: "1px solid #ddd",
    borderRadius: "100px",
    backgroundColor: "#fff",
    marginBottom: "10px",
    textAlign: "left",
    maxWidth: "fit-content", // Adjust width based on content
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  assignedItem: {
    display: "flex",
    alignItems: "center",
    padding: "20px", // Adjust padding: top, right, bottom, left
    border: "1px solid #ddd",
    borderRadius: "100px",
    backgroundColor: "#fff",
    marginBottom: "10px",
    textAlign: "left",
    maxWidth: "fit-content", // Adjust width based on content
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },

  assignedIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    //backgroundColor: iconColor,
    marginRight: "10px",
  },
  assignedIcon: {
    color: "#fff",
  },
  assignedText: {
    color: "#000",
    fontSize: "18px",
    fontFamily: "Roboto-Medium",
    textAlign: "left",
  },
  avatarIcon: {
    color: "#007ae4",
    marginRight: "15px",
  },
  profileImage: {
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    border: "3px solid #007ae4",
    marginRight: "15px",
    objectFit: "cover",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
  },
  detailContainer: {
    padding: "40px",
    backgroundColor: "#f5f5f5",
    borderRadius: "15px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    position: "relative" as "relative",
  },
  orderCardHeader: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "20px",
  },
  orderDetails: {
    width: "100%", // Ensure the order details use full width
    display: "flex",
    flexDirection: "column",
  },
  userName: {
    margin: 0,
    fontSize: "20px",
    fontFamily: "Poppins, Poppins, sans-serif",
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  iconTop: {
    marginLeft: "10px",
    marginRight: "5px",
    color: "#007ae4",
  },
  icon: {
    marginRight: "5px",
    color: "#007ae4",
  },
  ellipsis: {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Roboto",
    fontSize: 18,
    overflow: "hidden" as "hidden",
    textOverflow: "ellipsis" as "ellipsis",
  },
  serviceLabel: {
    display: "inline-flex",
    alignItems: "center",
    padding: "5px 10px",
    borderRadius: "5px",
    backgroundColor: "#e0f7e9",
    color: "#007b5e",
    fontSize: "14px",
    marginLeft: "5px",
  },
  serviceCharge: {
    marginLeft: "5px",
    fontFamily: "Roboto-Medium",
    fontSize: 18,
    color: "#007ae4",
  },
  orderStatus: {
    position: "absolute" as "absolute",
    fontFamily: "PoppinsMedium",
    fontSize: "20px",
    top: "40px",
    right: "40px",
    backgroundColor: "#007bff",
    color: "white",
    padding: "5px 15px",
    borderRadius: "50px",
  },
  separator: {
    width: "100%",
    margin: "10px 0",
    borderTop: "1px solid #ddd",
  },
  label: {
    fontSize: "16px",
    fontFamily: "Roboto-Medium",
    //fontWeight: "600",
    marginBottom: "5px",
    marginTop: "10px",
    color: "#000",
    textAlign: "left",
    whiteSpace: "nowrap", // Ensure the text stays on one line
    overflow: "hidden", // Hide any overflow text
    textOverflow: "ellipsis", // Show ellipsis (...) for overflow text
  },

  addressItem: {
    display: "flex",
    alignItems: "center",
    padding: "20px", // Adjust padding: top, right, bottom, left
    border: "1px solid #ddd",
    borderRadius: "100px",
    backgroundColor: "#fff",
    marginBottom: "10px",
    textAlign: "left",
    maxWidth: "fit-content", // Adjust width based on content
  },
  addressIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    //backgroundColor: iconColor,
    marginRight: "10px",
  },
  addressIcon: {
    color: "#fff",
  },
  addressText: {
    color: "#000",
    fontSize: "18px",
    fontFamily: "Roboto-Medium",
    textAlign: "left",
  },
  serviceIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    //backgroundColor: iconColor,
    marginRight: "10px",
  },
  serviceIcon: {
    color: "#fff",
  },
  serviceText: {
    color: "#000",
    fontSize: "16px",
    fontFamily: "Roboto-Medium",
    textAlign: "left",
  },
  buttonBar: {
    position: "sticky", // Use sticky positioning
    bottom: "0", // Stick to the bottom of the container
    backgroundColor: "#f5f5f5",
    padding: "10px 20px", // Add padding for spacing
    display: "flex", // Use flexbox for layout
    justifyContent: "space-between", // Space the buttons apart
    alignItems: "center", // Center align items vertically
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)", // Add a shadow for visual separation
    zIndex: 1000, // Ensure it is above other elements
    borderBottomLeftRadius: "15px", // Add border radius to bottom-left corner
    borderBottomRightRadius: "15px", // Add border radius to bottom-right corner
  },

  buttonContainer: {
    display: "flex",
    gap: "10px", // Add some space between the buttons
  },
  backButton: {
    padding: "25px 40px",
    fontFamily: "Roboto-Bold",
    fontSize: "20px",
    //backgroundColor: iconColor,
    color: "white",
    border: "none",
    borderRadius: "50px",
    cursor: "pointer",
  },
  downloadButton: {
    padding: "25px 50px",
    fontFamily: "Roboto-Bold",
    fontSize: "20px",
    //backgroundColor: iconColor,
    color: "white",
    border: "none",
    borderRadius: "50px",
    cursor: "pointer",
  },
};
export default BookingDetail;
