import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../App";
import { Customer } from "./store/actions/types";

//SWAP TO THIS ONE FOR LOCAL SERVER
//export const SERVER_URL = "http://localhost:2888";

//THIS THE IP OF OUR CURRENT LINUX SERVER HOSTING THE NODE INSTANCE ON AND LISTENING ON THIS PORT
//digitalOcean
//export const SERVER_URL = 'http://164.92.198.165:3000';

//AWS
export const SERVER_URL = 'https://www.dripautocare.com/server';




// Function to fetch provider document
export const fetchProvider = async (providerId: any) => {
  try {
    // Create a reference to the "providers" collection document with the given providerId
    const docRef = doc(db, "Providers", providerId);

    // Fetch the document
    const docSnap = await getDoc(docRef);

    // Check if the document exists
    if (docSnap.exists()) {
      // Include the document ID as UID in the returned object
      return {
        uid: docSnap.id,
        ...docSnap.data(),
      };
    } else {
      // Document not found
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    // Handle the error
    console.error("Error fetching document:", error);
    throw error;
  }
};

export const fetchCustomer = async (customerId: string): Promise<any> => {
  try {
    // Create a reference to the "Users" collection document with the given customerId
    const docRef = doc(db, "Users", customerId);

    // Fetch the document
    const docSnap = await getDoc(docRef);

    // Check if the document exists
    if (docSnap.exists()) {
      // Include the document ID as UID in the returned object
      console.log(docSnap.data)
      return {
        uid: docSnap.id,
        ...docSnap.data(),
      };
    } else {
      // Document not found
      console.log("No such customer document!");
      return null;
    }
  } catch (error) {
    // Handle the error
    console.error("Error fetching customer document:", error);
    throw error;
  }
};


export const fetchProcessHistory = async (orderId: string) => {
  try {
    // Create a query to fetch documents where orderId matches the given string
    const q = query(
      collection(db, "ProcessHistory"),
      where("orderId", "==", orderId)
    );

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Map through the matching documents
    const updatedHistory = querySnapshot.docs
      .map((doc) => {
        const data = doc.data();
        let formattedTimestamp: string;

        // Ensure the timestamp is a number and convert it to a date string
        if (typeof data.timestamp === "number") {
          formattedTimestamp = new Date(data.timestamp).toLocaleString();
        } else {
          formattedTimestamp = "Unknown Timestamp";
        }

        return {
          id: doc.id,
          actionType: data.actionType || "No Action Type",
          newStatus: data.newStatus || "Error Occurred",
          prevStatus: data.prevStatus || "No Previous Status",
          orderId: data.orderId || "No Order ID",
          customerUid: data.customerUid || "Error loading Customer Data",
          providerUid: data.providerUid || "No Provider Assigned",
          timestamp: data.timestamp, // Keep the original timestamp for sorting
          formattedTimestamp, // Use formatted timestamp for display
          actionByUid: data.actionByUid || "Unknown User",
        };
      })
      .sort((a, b) => {
        // Sort using the original timestamp for accuracy
        return b.timestamp - a.timestamp;
      });

    return updatedHistory;
  } catch (error) {
    console.error("Error fetching process history for orderId:", orderId, error);
    throw error; // Or handle error appropriately
  }
};

export const formatEpochDate = (milliseconds: any) => {
  // Create a new Date object with the milliseconds value
  const date = new Date(milliseconds);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract date components
  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Return the formatted date string (e.g., "Jul 11, 2023")
  return `${month} ${day}, ${year}`;
};

export const formatEpochTime = (milliseconds: any) => {
  // Create a new Date object with the milliseconds value
  const date = new Date(milliseconds);

  // Extract time components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Return the formatted time string (e.g., "08:24")
  return `${hours}:${minutes}`;
};

export const convertImageToBase64 = (file:any) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("No file provided");
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = 500;
        canvas.height = 500;
        //@ts-ignore
        ctx.drawImage(img, 0, 0, 500, 500);
        const dataUrl = canvas.toDataURL("image/jpeg");
        const base64String = dataUrl.split(",")[1]; // Extracts base64 part of the data URL
        resolve(base64String);
      };
      img.onerror = reject;
      //@ts-ignore
      img.src = e.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const formatBase64Image = (base64: string) => {
  if (!base64.startsWith("data:image")) {
    return `data:image/jpeg;base64,${base64}`; // Assuming it's a JPEG. Change if necessary.
  }
  return base64;
};

export function formatDateFromTimestamp(timestamp: any) {
  const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  return date.toISOString().split("T")[0]; // Format to YYYY-MM-DD
}

export function formatDateTimeFromTimestamp(timestamp: any) {
  if (!timestamp) {
    return "Invalid Date";
  }

  try {
    // Handle Firestore Timestamps (seconds and nanoseconds)
    if (timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
      return date.toISOString().replace("T", " ").split(".")[0]; // Format to YYYY-MM-DD HH:MM:SS
    }

    // Handle UNIX timestamps (number or string)
    const date = new Date(timestamp);
    if (!isNaN(date.getTime())) {
      return date.toISOString().replace("T", " ").split(".")[0]; // Format to YYYY-MM-DD HH:MM:SS
    }

    return "Invalid Date"; // Fallback for invalid formats
  } catch (error) {
    console.error("Error formatting timestamp:", error);
    return "Invalid Date";
  }
}

export const formatFirestoreTimestamp = (timestamp: any, includeTime: boolean = false) => {
  if (!timestamp || typeof timestamp !== "object" || !timestamp._seconds) {
    return "Invalid Date";
  }

  try {
    const date = new Date(timestamp._seconds * 1000); // Convert Firestore seconds to milliseconds

    if (includeTime) {
      // Return date with time: YYYY-MM-DD HH:MM:SS
      return date.toISOString().replace("T", " ").split(".")[0];
    } else {
      // Return date only: YYYY-MM-DD
      return date.toISOString().split("T")[0];
    }
  } catch (error) {
    console.error("Error formatting Firestore timestamp:", error);
    return "Invalid Date";
  }
};


export const formatDate = (dateString: any) => {
  const date = new Date(
    dateString.replace(
      /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/,
      "$2/$1/$3 $4:$5:$6"
    )
  );
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatTime = (dateTimeString: string) => {
  const date = new Date(
    dateTimeString.replace(
      /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/,
      "$2/$1/$3 $4:$5:$6"
    )
  );
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Set to true for 12-hour format, false for 24-hour format
  });
};
