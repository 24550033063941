export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const ADD_ORDERS = 'ADD_ORDERS';
export const ADD_CUSTOMERS = 'ADD_CUSTOMERS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

export const SET_LOADING_ORDERS = 'SET_LOADING_ORDERS';
export const SET_LOADING_CUSTOMERS = 'SET_LOADING_CUSTOMERS';
export const SET_LOADING_INVOICES = 'SET_LOADING_INVOICES';
export const SET_LOADING_PROVIDER = 'SET_LOADING_PROVIDER';


export const SET_PROVIDER_PROFILE_DETAILS = 'SET_PROVIDER_PROFILE_DETAILS';
export const SET_LOADING_PROVIDER_PROFILE = 'SET_LOADING_PROVIDER_PROFILE';

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const ADD_INVOICES = 'ADD_INVOICES';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';

export const FETCH_ZONES = 'FETCH_ZONES';
export const FETCH_REGIONS = 'FETCH_REGIONS';


export interface Order {
    id: string;
    orderID: string; // Added missing property
    dateOrderPlaced: number;
    dateOfService: string; // Added missing property
    timeOfService: string; // Added missing property
    address: {
      stateAddress: string;
      cityAddress: string;
      location: { latitude: number; longitude: number };
      streetAddress: string;
      zipCodeAddress: string;
    };
    orderPrice: string;
    service: string;
    orderStatus: string;
    orderedBy: string;  // Added orderedBy field

    dropOffPreference: string; // Added missing property
    provider?: string; // Added missing property
    vehicle?: Vehicle; // Added missing property

    //these are conditionally available depending on the state, the data above is required for an order 
    regionId: string;
    currentZoneId: string;
    onRouteToOngoing: number;
    placedToAccepted: number;
    acceptedToOnRoute: number;
    eta: number;
    providerRate: number;
    regionalFactorMultiplier: number;
    distanceOnRoute: number;
    distanceOngoing: number;
    rating: number;
    //admin only should load this
    providerPriority: number;
    elevate: boolean;
    payment: string;
    inspectImageUrls: [];
  }

  
  export interface Invoice {
    id: string;
    orderId: string;
    providerId: string;
    customerId: string;
    orderPrice: string;
    finalInvoiceAmount: number;
    providerAmount: number;
    orderZipCode: string;

    orderDateTimePlaced: number;
    orderDateTimeService: number;
    orderDateTimeCompleted: number;
    timestamp: number;
    timestampSent:number;

    orderService: string;
    placedToAccepted: number;
    acceptedToOnRoute: number;
    onRouteToOngoing: number;
    distanceOnRoute: number;
    distanceOngoing: number;
    couponValue: number;
    subscriptionDiscount: number;
    onDemandSurcharge: number;
    regionalFactorMultiplier: number;
    providerRate: number;
    providerPriority: number;
    invoiceStatus: string; // Add this line if invoiceStatus is a valid field
    alerts?: string; // Add this line for the alerts field
    orderStatus?:string;
    pdf?:string;
}


export interface Provider {
  uid?: string; // Changed UID to uid
  verified?: boolean;

  businessName?: string;
  homeAddress?: string;
  emailAddress?: string;
  ownerName?: string;
  geographicalCoverage?: string;
  dispatcherPhoneNumber?: string;
  profileImage?: string;
  phoneNumber?: string;

  workingHours?: string;
  services?: string;
  createdAt?: string;
  paymentDocument?: string;
  proofOfInsurance?: string;
  businessLicense?: string;
}

export interface Customer {
  uid: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  profileImage: string;
  dateOfBirth: string;
  createdAt: string;
  gender: string;
  stripeCustomerId: string;
  verified: boolean;
  addresses: {
      location: { latitude: number; longitude: number; latitudeDelta?: number; longitudeDelta?: number };
      stateAddress: string;
      cityAddress: string;
      streetAddress: string;
      zipCodeAddress: string;
  }[];
  vehicles: {
      model: string;
      color: string;
      make: string;
      imageSource: string;
      vinNumber: string;
  }[];
}




export interface Vehicle {
  model: string;
  color: string;
  make: string;
  imageSource: string; // URL or base64 encoded string
  vinNumber?: string;
  regNumber?: string; // Added missing property (optional if using vinNumber instead)
}

export interface Assignment {
  id: string;
  ZipCode: string;
  priority: number;
  rate: number;
}


export interface UserData {
  uid: string;
  emailAddress: string;
  assignments?: Assignment[];
}

export interface ZipCode {
  id: number;
  zipCode: string;
  // Add other properties as needed
}

export interface PricingData {
  id: string;
  Value: number;
}

export interface Coupon {
  Name: string;
  Code: string;
  Amount: number;
  Value: number;
}

export interface Location {
  latitude: number;
  longitude: number;
  latitudeDelta?: number;
  longitudeDelta?: number;
}

export interface Address {
  streetAddress: string;
  stateAddress: string;
  cityAddress: string; // Added cityAddress as per new types
  zipCodeAddress: string;
  location: Location;
}




  export interface Action {
    type: string;
    payload: any;
  }