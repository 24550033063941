import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import "./Authentication.css?v=1";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import VerifyProviderModal from "./VerifyProviderModal";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../App";
import RowHeader from "./RowHeaderNew";
import ProviderRow from "./ProviderRow";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import TitleBar from ".././TitleBar";
import { SERVER_URL } from ".././Functions";

import GradientContainer from "./GradientContainer"; // Import the new component
import ProvidersGrid from "./ProviderGrid";
import { Provider, UserData } from "../store/actions/types";
import LoadingSpinner from "../LoadingSpinner";

//loading is scuffed when modal closes and it refreshes

const Providers: React.FC = () => {
  const auth = getAuth();

  const [user, setUser] = useState<User | null>(null);
  //const [users, setUsers] = useState<UserData[]>([]); // State for storing fetched users

  const providers = useSelector(
    (state: RootState) => state.providers.providers
  );

  //console.log(providers)
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [mergedProviders, setMergedProviders] = useState<Provider[]>([]); // State for merged provider data


  const fetchProviderDetails = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/api/providers`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
  
      console.log("Server response:", data.providers);
  
      // Preprocess the `createdAt` field for each provider
      const processedProviders = data.providers.map((provider: any) => {
        if (provider.createdAt) {
          if (provider.createdAt._seconds) {
            // Convert Firestore Timestamp to a `seconds` property for compatibility
            provider.createdAt = {
              seconds: provider.createdAt._seconds,
              nanoseconds: provider.createdAt._nanoseconds || 0,
            };
          } else if (provider.createdAt.seconds) {
            // Ensure it has the correct structure
            provider.createdAt = {
              seconds: provider.createdAt.seconds,
              nanoseconds: provider.createdAt.nanoseconds || 0,
            };
          } else {
            // Handle UNIX timestamps by converting them into Firestore-like format
            const unixTimestamp = provider.createdAt;
            provider.createdAt = {
              seconds: Math.floor(unixTimestamp / 1000),
              nanoseconds: (unixTimestamp % 1000) * 1e6,
            };
          }
        } else {
          // Set a default for missing `createdAt` fields
          provider.createdAt = null;
        }
        return provider;
      });
  
      // Update the state with processed providers
      setMergedProviders(processedProviders);
    } catch (error) {
      console.error("Error fetching provider details:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchProviderDetails();
  }, []);
  
  
  

  const [displayMode, setDisplayMode] = useState("list"); // 'list' or 'grid'

  //console.log(providers);

  const verifiedProviders = mergedProviders.filter((provider) => provider.verified);
  const unverifiedProviders = mergedProviders.filter((provider) => !provider.verified);
  

  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close

  const [selectedUid, setSelectedUid] = useState<string | null>(null); // Track the selected UID

  const [deletingStatus, setDeletingStatus] = useState<{
    [uid: string]: boolean;
  }>({});

  useEffect(() => {
    // Fetch user data when the component mounts
    //fetchUsers();
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const openModal = (userData: UserData) => {
    setSelectedUid(userData.uid); // Set the selected UID when the button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = async () => {
    setIsModalOpen(false);
    //await fetchProviders(); // Fetch the data again to refresh
  };

  const deleteProvider = async (uid: any) => {
    setDeletingStatus((prev) => ({ ...prev, [uid]: true })); // Set loading state for this UID

    try {
      // Send a DELETE request to your server API endpoint
      const response = await fetch(`${SERVER_URL}/api/delete-provider/${uid}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response from the server
      const result = await response.json();
      console.log(result.message); // Or handle the success message as you see fit

      // Refresh the provider lists after deletion
      //await fetchProviders();
    } catch (error) {
      console.error("Error deleting provider:", error);
      // Handle the error as needed
    }
    setDeletingStatus((prev) => ({ ...prev, [uid]: false })); // Unset loading state for this UID
  };

  const titles = {
    image: "",
    email: "EMAIL",
    created: "MEMBER SINCE",
    details: "DETAILS",
  };

  const percentages = {
    image: "10%",
    email: "35%",
    created: "35%",
    details: "20%",
  };

  /*

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/users`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      setUsers(data.unverifiedProviders); // Update the state with the fetched user data
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching user data:', error);
      throw error; // Handle the error as needed in your component
    }
  };   */

  const renderProviders = (
    providers: Provider[],
    title: string,
    listToggle: boolean
  ) => (
    <>
      <TitleBar
        title={title}
        {...(listToggle && { displayMode, setDisplayMode })}
      />
      <RowHeader
        titles={titles}
        percentages={percentages}
        {...(listToggle && { displayMode })}
      />
      {providers.length > 0 ? (
        providers.map((provider, index) => (
          <ProviderRow key={index} provider={provider} />
        ))
      ) : (
        <NoRecords />
      )}
    </>
  );

  const renderProvidersGrid = (
    providers: Provider[],
    title: string,
    listToggle: boolean
  ) => (
    <>
      <TitleBar
        title={title}
        {...(listToggle && { displayMode, setDisplayMode })}
      />
      {providers.length > 0 ? (
        <ProvidersGrid providers={providers} />
      ) : (
        <NoRecords />
      )}
    </>
  );

  return (
    <div
      style={{
        maxHeight: "100vh", // Ensure it fills the viewport height
        overflowY: "auto",
        padding: 10,
      }}
    >
      {isLoading ? (
      <LoadingSpinner />
    ) : displayMode === "list" ? (
      <>
        {renderProviders(verifiedProviders, "Active Providers", true)}
        <div style={{ paddingTop: 50 }} />
        {renderProviders(unverifiedProviders, "Unverified Providers", false)}
      </>
    ) : (
      <>
        {renderProvidersGrid(verifiedProviders, "Active Providers", true)}
        <div style={{ paddingTop: 50 }} />
        {renderProvidersGrid(unverifiedProviders, "Unverified Providers", false)}
      </>
    )}
    </div>
  );
};

const NoRecords: React.FC<{ message?: string }> = ({
  message = "No Records Found",
}) => {
  return (
    <div
      style={{
        padding: "50px",
        marginLeft: "10%",
        marginRight: "10%",
        borderRadius: 15,
        marginTop: 50,
        textAlign: "center",
        fontFamily: "PoppinsLight",
        backgroundColor: "#f5f5f5",
        fontSize: 35,
      }}
    >
      {message}
    </div>
  );
};

const animatedGradientContainer: React.CSSProperties = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 50,
  height: "100%",

  background: "linear-gradient(-45deg, #98fbc1, #7fe0fe, #98fbc1, #7fe0fe)",
  backgroundSize: "400% 400%",
  animation: "gradientAnimation 15s ease infinite",
};

const titleStyle: React.CSSProperties = {
  fontSize: 36,
  marginLeft: 50,
  fontWeight: 700,
};

const columnStyle: React.CSSProperties = {
  flex: 1,
  padding: 20,
};

const rowStyles: React.CSSProperties = {
  //borderRadius: '10px',
  padding: "40px",
  border: "1px solid #000000",
  margin: "10px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  color: "black",
  fontSize: "20px",
};

export default Providers;
