//@ts-nocheck

import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import "./Authentication.css?v=1";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import VerifyProviderModal from "./VerifyProviderModal";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../App";
import TitleBar from ".././TitleBar";
import RowHeader from "./RowHeaderNew";
import CustomerRow from "./CustomerRow";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { fetchCustomer, SERVER_URL } from ".././Functions";
import GradientContainer from "./GradientContainer"; // Import the new component
import CustomersGrid from "./CustomerGrid";
import { UserData } from "../store/actions/types";
import LoadingSpinner from "../LoadingSpinner";

//loading is scuffed when modal closes and it refreshes

const Customers: React.FC = () => {
  const auth = getAuth();

  const customers = useSelector(
    (state: RootState) => state.customers.customers
  );

  const [displayMode, setDisplayMode] = useState("list"); // 'list' or 'grid'

  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<UserData[]>([]); // State for storing fetched users

  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const [selectedUid, setSelectedUid] = useState<string | null>(null); // Track the selected UID

  const [deletingStatus, setDeletingStatus] = useState<{
    [uid: string]: boolean;
  }>({});

  useEffect(() => {
    // Fetch user data when the component mounts
    //fetchUsers();
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Listen for changes in the Providers collection
    const unsubscribe = onSnapshot(collection(db, "Users"), (snapshot: any) => {
      // Check if there are changes
      if (!snapshot.empty) {
        fetchCustomerDetails(); // Fetch updated users if there's a change
      }
    });

    // Cleanup listener when component unmounts
    return () => unsubscribe();
  }, []);

  const openModal = (userData: UserData) => {
    setSelectedUid(userData.uid); // Set the selected UID when the button is clicked
    setIsModalOpen(true);
  };

  const handleModalClose = async () => {
    setIsModalOpen(false);
    await fetchCustomerDetails(); // Fetch the data again to refresh
  };

  const deleteCustomer = async (uid: any) => {
    setDeletingStatus((prev) => ({ ...prev, [uid]: true })); // Set loading state for this UID

    try {
      // Send a DELETE request to your server API endpoint for customers
      const response = await fetch(`${SERVER_URL}/api/delete-customer/${uid}`, {
        method: 'DELETE',
        credentials: 'include', // Include credentials
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer YOUR_AUTH_TOKEN', // If applicable
        },
      });
      

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response from the server
      const result = await response.json();

      console.log(result.message); // Handle the success message

      // Refresh the customer list after deletion
      await fetchCustomerDetails();
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
    setDeletingStatus((prev) => ({ ...prev, [uid]: false })); // Unset loading state for this UID
  };

  const fetchCustomerDetails = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/api/customers`, {
        method: "GET",
        credentials: "include", // Include credentials
        headers: {
          "Content-Type": "application/json",
          //'Authorization': 'Bearer YOUR_AUTH_TOKEN', // If applicable
        },
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();

      console.log("Server response:", data.customers); // Log server response

      const customerDetails = await Promise.all(
        data.customers.map(
          async (customer: { uid: string; emailAddress: string }) => {
            try {
              const customerData = await fetchCustomer(customer.uid);
              if (customerData) {
                const mergedCustomer = {
                  ...customerData,
                  emailAddress: customer.emailAddress,
                };
                console.log("Merged customer data:", mergedCustomer); // Log merged data
                return mergedCustomer;
              }
              return null;
            } catch (error) {
              console.error(
                `Error fetching data for customer UID: ${customer.uid}`,
                error
              );
              return null; // Skip failed fetch
            }
          }
        )
      );

      // Filter out null values and set the state
      setUsers(customerDetails.filter((doc) => doc !== null));
    } catch (error) {
      console.error("Error fetching customer details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //@ts-ignore
  const unverifiedCustomers = users.filter((customer) => !customer.dateOfBirth);
  //@ts-ignore
  const verifiedCustomers = users.filter((customer) => customer.dateOfBirth);

  //console.log(users);

  /*
  useEffect(() => {
    fetchCustomers();
  }, []);
    */

  /*

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/users`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      setUsers(data.unverifiedProviders); // Update the state with the fetched user data
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching user data:', error);
      throw error; // Handle the error as needed in your component
    }
  };   */

  const gridStyles: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))", // Adjust for phone number grid
    gap: "20px",
    padding: "20px",
  };

  const titles = {
    image: "",
    email: "CUSTOMER EMAIL",
    name: "FULL NAME",
    created: "MEMBER SINCE",
    details: "DETAILS",
  };

  const percentages = {
    image: "5%",
    email: "25%",
    name: "25%",
    created: "25%",
    details: "20%",
  };

  return (
    <div
      style={{
        padding: 10,
        maxHeight: "100vh", // Ensure it fills the viewport height
        overflowY: "auto",
      }}
    >
      <TitleBar
        title="Customers"
        setDisplayMode={setDisplayMode}
        displayMode={displayMode}
      />

      {isLoading ? (
        <LoadingSpinner />
      ) : displayMode === "grid" ? (
        <>
          <h3 style={sectionHeaderStyle}>Verified Customers</h3>
          {verifiedCustomers.length === 0 ? (
            <p>No verified customers</p>
          ) : (
            <CustomersGrid customers={verifiedCustomers} />
          )}

          <h3 style={sectionHeaderStyle}>Unverified Customers</h3>
          {unverifiedCustomers.length === 0 ? (
            <p>No unverified customers</p>
          ) : (
            <CustomersGrid customers={unverifiedCustomers} />
          )}
        </>
      ) : (
        <>
          <h3 style={sectionHeaderStyle}>Verified Customers</h3>
          <RowHeader titles={titles} percentages={percentages} />
          {verifiedCustomers.length === 0 ? (
            <p>No verified customers</p>
          ) : (
            verifiedCustomers.map((customer, index) => (
              <CustomerRow key={`verified-${index}`} customer={customer} />
            ))
          )}

          <h3 style={sectionHeaderStyle}>Unverified Customers</h3>
          <RowHeader titles={titles} percentages={percentages} />
          {unverifiedCustomers.length === 0 ? (
            <p>No unverified customers</p>
          ) : (
            unverifiedCustomers.map((customer, index) => (
              <CustomerRow key={`unverified-${index}`} customer={customer} />
            ))
          )}
        </>
      )}
    </div>
  );
};

const sectionHeaderStyle: React.CSSProperties = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "24px", // Adjust the size as needed
  fontWeight: 600, // Adjust for boldness
  margin: "20px 0", // Adjust spacing
  color: "#333", // Adjust text color
};

const animatedGradientContainer: React.CSSProperties = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 50,
  height: "100%",

  background: "linear-gradient(-45deg, #98fbc1, #7fe0fe, #98fbc1, #7fe0fe)",
  backgroundSize: "400% 400%",
  animation: "gradientAnimation 15s ease infinite",
};

const columnStyle: React.CSSProperties = {
  flex: 1,
  padding: 20,
};

const rowStyles: React.CSSProperties = {
  //borderRadius: '10px',
  padding: "40px",
  border: "1px solid #000000",
  margin: "10px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  color: "black",
  fontSize: "20px",
};

export default Customers;
