import React, { useEffect, useState } from "react";
import DoughnutChart from "./DoughnutChart"; // Adjust the import path as necessary
import { getAuth } from "firebase/auth";
import { SERVER_URL } from "../Functions";

const fetchProviderMetrics = async (providerUid:any) => {
  const response = await fetch(`${SERVER_URL}/calculateProviderMetrics`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ providerUid }),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch provider metrics");
  }

  return await response.json();
};

const Analytics = () => {
  const [metrics, setMetrics] = useState({
    completionRate: 0,
    denialRate: 0,
    etaAccuracyRate: 0,
  });
  const [loading, setLoading] = useState(true);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user && !hasFetched) {
      setHasFetched(true); // Prevent multiple fetches
      fetchProviderMetrics(user.uid)
        .then((data) => {
          setMetrics({
            completionRate: data.completionRate,
            denialRate: data.denialRate,
            etaAccuracyRate: data.etaAccuracyRate,
          });          
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching provider metrics:", error);
          setLoading(false);
        });
    }
  }, [hasFetched]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div style={{ padding: 20, height: "100vh", boxSizing: "border-box" }}>
      <div
        style={{
          backgroundColor: "#E9E9EA",
          borderRadius: 40,
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "20px",
          padding: "20px",
          height: "calc(100vh - 40px)",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <DoughnutChart
            label="Service Completion Rate"
            percentage={metrics.completionRate}
            completedLabel="Completed"
            notCompletedLabel="Not Completed"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <DoughnutChart
            label="Service Request Rejection Rate"
            percentage={100 - metrics.denialRate} // Invert the percentage
            completedLabel="Accepted"
            notCompletedLabel="Rejected"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <DoughnutChart
            label="Request Reassign Rate THIS ONES FAKE DATA"
            percentage={68} // Keep this hardcoded
            completedLabel="By Service Provider"
            notCompletedLabel="By Drip Auto Care"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <DoughnutChart
            label="Employee ETA Accuracy Rate"
            percentage={metrics.etaAccuracyRate}
            completedLabel="On Time"
            notCompletedLabel="Late"
          />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
