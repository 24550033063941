import React, { useEffect, useState } from "react";
import CustomerModal from "./CustomerModal"; // Assuming there's a modal specifically for customers
import { formatBase64Image } from ".././Functions"; // Assuming this function can be reused
import { MdAccountCircle, MdEmail, MdPhone } from "react-icons/md";
import { Customer } from "../store/actions/types";

interface CustomersGridProps {
  customers: Customer[];
}

const CustomersGrid: React.FC<CustomersGridProps> = ({ customers }) => {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //console.log(customers)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCustomerClick = (customer: Customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const gridStyles: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(500px, 1fr))",
    gap: "30px",
    padding: "30px",
  };

  const gridItemStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    fontFamily: "Poppins",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "15px",
    padding: "20px",
    backgroundColor: "#f5f5f5",
    overflow: "hidden",
    boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
  };

  const imageStyles: React.CSSProperties = {
    width: "65px",
    height: "65px",
    borderRadius: "25%",
    border: "3px solid #007ae4",
    objectFit: "cover",
    marginBottom: "40px",
    alignSelf: "center", // Center the image within its row
  };

  const textContainerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    padding: "0 30px",
    //maxWidth: "400px",
  };

  const iconStyle: React.CSSProperties = {
    marginRight: "6px",
    color: "#007ae4",
    verticalAlign: "middle",
  };

  const valueStyle: React.CSSProperties = {
    fontSize: 16,
    marginBottom: "5px", // Space between each item
    display: "flex",
    alignItems: "center", // This ensures that the icon and text align vertically
  };

  return (
    <div style={gridStyles}>
      {customers.map((customer) => (
        <div
          //@ts-ignore
          key={customer.id}
          style={gridItemStyles}
          onClick={() => handleCustomerClick(customer)}
        >
          {customer.profileImage ? (
            <img
              src={formatBase64Image(customer.profileImage)}
              alt="Customer"
              style={imageStyles}
            />
          ) : (
            <MdAccountCircle
              size="100"
              style={{ ...imageStyles, color: "#007ae4" }}
            />
          )}
          <div style={textContainerStyles}>
            <div
              style={{
                ...valueStyle,
                fontFamily: "PoppinsMedium, Poppins, sans-serif",
                fontSize: 22,
              }}
            >
              {customer.firstName || "Yet to complete Verification"}{" "}
              {customer.lastName || ""}
            </div>
            <div style={valueStyle}>
              <MdEmail size={18} style={iconStyle} />
              {customer.emailAddress || "Email Missing"}
            </div>
            <div style={valueStyle}>
              <MdPhone size={18} style={iconStyle} />
              {customer.phoneNumber || "N/A"}
            </div>
          </div>
        </div>
      ))}
      {isModalOpen && selectedCustomer && (
        <CustomerModal
          customerInfo={selectedCustomer}
          onClose={handleCloseModal}
          isOpen={isModalOpen}
        />
      )}
    </div>
  );
};

export default CustomersGrid;
